var DOM = React.DOM;
var {div, section, span, ul, li, time, div, h3, h4, h5, h6, a, p, iframe} = React.DOM;

import EventMonth from "./EventMonth.js"

module.exports = React.createFactory(React.createClass({
  displayName: "eventList",
  
  filter: function(ele) {
    return ( (ele.labelIds.indexOf(this.props.classFilter) != -1) || (!this.props.classFilter) ) 
      && ( (ele.locationId==this.props.locationFilter) || (!this.props.locationFilter) )
  },

  render: function() {
    var eventsByMonth = {};
    var eventCount = 0;
    var events = (this.props.events || []).concat();

    events.sort((a, b) => a.date - b.date)

    for(var e of events.filter(this.filter)) { 
      var date = moment(e.date);
      var key = date.format("YYYY-MM");
      eventCount++;

      if (eventsByMonth[key]) {
        eventsByMonth[key].events.push(e);
      } else {
        eventsByMonth[key] = {
          events: [e],
          date: e.date,
          title: date.format('MMMM YYYY'),
          key: key
        };
      }

      if (this.props.limit && eventCount >= this.props.limit) {
        break;
      }
    }

    var eventsByMonth = Object.keys(eventsByMonth).map((key, i) => {
      return eventsByMonth[key]
    })

    eventsByMonth.sort((a, b) => a.date - b.date)

    return section({className: "eventList"},
      (!this.props.condensed)
        ? p({className: "eventList__bookingTip"},
            "Click on a session to book your place!"
          )
        : null,
      (eventsByMonth.length >0) 
        ? eventsByMonth.map((eventMonth, i) => {
          if (eventMonth.events.length >0) {
            return EventMonth({
              key: eventMonth.title,
              title: eventMonth.title,
              events: eventMonth.events,
              classes: this.props.classes,
              onClickDay: this.props.onClickDay,
              openedEvent: this.props.openedEvent            
            });
          }
        })
        : h3({className: "eventList__monthTitle"}, "No Results")
    );
  }//end render
}));
