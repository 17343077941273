var DOM = React.DOM;
var {div, section, span, ul, li, time, div, h3, h4, h5, h6, a, p, iframe} = React.DOM;


module.exports = React.createFactory(React.createClass({
  displayName: "Event",

  renderPrice: function(price) {
    if (parseInt(price) == 0) {
      return "FREE"
    } else {
      return "$" + price
    }
  },
  
  render: function() {
    var date = moment(this.props.date);
   
    var titleActive = {
      "event__summary": true,
      "is-active": (this.props.openedEvent == this.props.id)  
    };
    
    var classes = this.props.classFlowBookIds.map(
      (e) => { return this.props.classes[e] }
    );

    return li( {className: "eventDay", onClick: (e) => this.props.onClickDay(this.props.id)},
      this.props.isFirstEventForDay
        ? time({className: "eventDay__calendarDay calendarDay"},
            span({className: "calendarDay__date"}, date.format('D')),
            span({className: "calendarDay__day"}, date.format('ddd'))
          )
        : null,
      div({className: "eventDay__event event"},
        div({className: React.addons.classSet(titleActive)},
          h5({className: "event__title"},
             this.props.name
             // classes.length == 1 && classes[0].classPrice
             //   ? span(
             //       {className: "event__titlePrice"},
             //       "(" + this.renderPrice(classes[0].classPrice) + ")"
             //     )
             //   : null
          ),
          h6({className: "event__location"},
             this.props.location + ", " + date.format("h:mma")
          )
        ),
        div({className: "event__additional"},
          p({className: "event__extraClasses"}, 
            (classes.length > 1) 
              ? classes.map((c) => {
                var txt = c.className
                // if (c.classPrice) {
                //   txt += " (" + this.renderPrice(c.classPrice) + ")"
                // }
                return txt
              }).join(", ") 
              : null
          ),

          (this.props.openedEvent == this.props.id) 
            ? p({dangerouslySetInnerHTML: {"__html": this.props.description}})
            : null,

          (this.props.openedEvent == this.props.id) 
            ? iframe({className: "event__iframe", src: "booking-frame/?event_id="+this.props.id})
            : null
        )
      )
    ); 
  }//end render
}));
