  //dangerouslySetInnerHTML: { __html: this.props.description}

var DOM = React.DOM;
var {div, section, span, ul, li, time, div, h3, h4, h5, h6, a, p, iframe} = React.DOM;

//var MONTHNAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
//var DAYNAMES =["Sun","Mon","Tue","Wed","Thur","Fri","Sat"];

import EventList from "./events/EventList.js"
import EventFilter from "./events/EventFilter.js"
require("babelify/polyfill");


var Events = React.createFactory(React.createClass({
  displayName: "events",
  
  getInitialState: function() {
    return {
      classFilter: this.props.initialClassFilter,
      locationFilter: this.props.initialLocationFilter,
      openedEvent: null
    };
  },

  onClickClass : function(id) {
    this.setState({ classFilter: id });
  },

  onClickLocation : function(id) {
    this.setState({ locationFilter: id });
  },

  onClickDay : function(id) {
    if (this.state.openedEvent == id) {
      this.setState({ openedEvent: null });
    } else {
      this.setState({ openedEvent: id });
    }
  },

  render: function() {
    if (this.props.eventStyle == "condensed") {
      return this.renderCondensed();
    };
    var classes = {};
    var locations = {};    
      
    for(var c of this.props.classes) {
      classes[c.classFlowBookId] =c;
    }

    for(var l of this.props.locations) {
      locations[l.locationFlowBookId] =l;
    }

    return div({className: "events"}, 
      section({className: "content--shortWidth content"},
        EventFilter({
          locations: this.props.locations,
          classes: this.props.classes,
          onClickLocation : this.onClickLocation,
          onClickClass : this.onClickClass,
          classFilter: this.state.classFilter,
          locationFilter: this.state.locationFilter  
        })
      ),
      section({className: "aside--largeWidth aside"},
        EventList({
          locations: locations,
          classes: classes,
          events: this.props.events,
          classFilter: this.state.classFilter,
          locationFilter: this.state.locationFilter,
          onClickDay: this.onClickDay,
          openedEvent: this.state.openedEvent
        })
      )
    );
  },//end render
  
  renderCondensed: function() {
    var classes = {};
    var locations = {};    
      
    for(var c of this.props.classes) {
      classes[c.classFlowBookId] =c;
    }

    for(var l of this.props.locations) {
      locations[l.locationFlowBookId] =l;
    }

    return div({className: "events"}, 
      EventList({
        locations: locations,
        classes: classes,
        events: this.props.events,
        condensed: true,
        limit: this.props.limit,
        classFilter: this.state.classFilter,
        locationFilter: this.state.locationFilter,
        onClickDay: this.onClickDay,
        openedEvent: this.state.openedEvent
      })
    );
  },
}));

jQuery(function($) {
  var element = $(".reactContainer")[0];
  var search = window.location.search.slice(1);
  var terms = search.split("&");
  moment.tz.setDefault("Australia/Melbourne")
  var finalTerms = {};
  for (var t of terms) {
    var parts = t.split("=");
    finalTerms[parts[0]] = parts[1];
  }

  var initialClassFilter = finalTerms.classevent || null;
  var initialLocationFilter = finalTerms.locationevent || null;

  if ($(element).data("events-class-filter")) {
    initialClassFilter = $(element).data("events-class-filter") + "";
  };

  $.ajax({
    dataType: "json",
    url: "/api/mpk/events/",
    success: function(data) {
      
      React.render(Events({
          locations: data.locations,
          limit: $(element).data("events-limit"),
          eventStyle: $(element).data("events-style"),
          initialClassFilter,
          initialLocationFilter,
          events: data.events,
          classes: data.classes
        }),
        element
      );

    }
  });
});
