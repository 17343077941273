
var DOM = React.DOM;
var {div, section, span, ul, li, time, div, h3, h4, h5, h6, a, p, iframe} = React.DOM;

//var MONTHNAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
//var DAYNAMES =["Sun","Mon","Tue","Wed","Thur","Fri","Sat"];

module.exports = React.createFactory(React.createClass({
  displayName: "eventFilter",
  
  render: function() {

    return section({className: "eventFilter"},
      h3({className: "eventFilter__title"},
        "Filters"
      ),
      h4({className: "eventFilter__subTitle"},
        "By Location"
      ),

      ul({className: "eventFilter__list"},
        this.props.locations.map((l) => {
            return FilterItem({ 
              key: l.locationFlowBookId,
              id: l.locationFlowBookId,     
              name: l.locationName, 
              click: this.props.onClickLocation,
              filter: this.props.locationFilter              
            })     
        }),
        FilterItem({
          name: "Show All",
          id: null,
          click: this.props.onClickLocation,
          filter: this.props.locationFilter 
        })
      ),
      h4({className: "eventFilter__subTitle"},
        "By Class Type"
      ),
      ul({className: "eventFilter__list"},
        this.props.classes.map((c) => {
            return FilterItem({ 
              id: c.classFlowBookId,     
              key: c.classFlowBookId,
              name: c.className, 
              click: this.props.onClickClass,
              filter: this.props.classFilter            
            })      
        }),
        FilterItem({
          name: "Show All",
          id: null,
          click: this.props.onClickClass,
          filter: this.props.classFilter 
        })
      )
    );
  }//end render
}));


var FilterItem = React.createFactory(React.createClass({
  displayName: "filterItem",

  render: function() {
    var filterItemClasses = {
      "filterItem": true,
      "is-active": (this.props.filter == this.props.id)  
    };
    
    return li({
        className: React.addons.classSet(filterItemClasses),
      onClick: (e) => this.props.click(this.props.id)
    },
      a({className: "filterItem__link"}, this.props.name)  
    );
  }//end render
}));
