var DOM = React.DOM;
var {div, section, span, ul, li, time, div, h3, h4, h5, h6, a, p, iframe} = React.DOM;

import Event from "./Event.js";

module.exports = React.createFactory(React.createClass({
  displayName: "EventMonth",
  render: function() {
    var lastDate = null;

    return div({},
      h3({className: "eventList__monthTitle"}, this.props.title),
      ul({className: "eventList__events"},              
        this.props.events.map((e) => {
          var firstEventForDay = !lastDate || !moment(e.date).isSame(lastDate, "day");
          lastDate = moment(e.date);

          return Event({ 
            id: e.id,
            key: e.id,
            isFirstEventForDay: firstEventForDay,
            name: e.name,
            date: e.date,
            description: e.description,
            location: e.location,
            classFlowBookIds: e.labelIds,
            classes: this.props.classes,
            onClickDay: this.props.onClickDay,
            openedEvent: this.props.openedEvent            
          })      
        })
      )
    );
  },
}))
